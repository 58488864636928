import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Drawer,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { Loading, Scrollable } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as SendLetterIcon } from 'assets/send-letter.svg';
import { CSVLink } from 'react-csv';
import { ToastDataContext, AuthDataContext } from 'contexts';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { gql, useQuery } from '@apollo/client';
import { getCurrentDateTime } from 'utils/dates';
import { formatStringForCSV } from 'utils/strings';
import LetterEditor from 'pages/Common/LetterEditor';
import InsightsOfferRow from './InsightsOfferRow';

const drawerWidth = '50vw';
const wideDrawerWidth = 'calc(100vw - 194px)';

const OfferInsightsDrawer = ({ open, insight, onClose }) => {
  const [wide, setwide] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [previousOpen, setPreviousOpen] = useState();
  const [showSellerId, setShowSellerId] = useState(null);
  const scrollPageLength = 20;
  const [limit, setLimit] = useState(scrollPageLength);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { region } = useContext(AuthDataContext);
  const [exportFilename, setExportFilename] = useState('');
  const [exportData, setExportData] = useState('');
  const [selectedCount, setSelectedCount] = useState(0);
  const [letterOffers, setLetterOffers] = useState(null);
  const [emailOffers, setEmailOffers] = useState(null);

  const theme = useTheme();

  const updateExport = () => {
    if (offers?.length > 0) {
      const csvData = [['Product', 'ASIN', 'ProductLink', 'Seller', 'SellerLink', 'BusinessName', 'BusinessAddress', 'Win Rate', 'Score', 'Price', 'MAP', 'Diff']];
      for (let i = 0; i < offers.length; i += 1) {
        const offer = offers[i];
        csvData.push([
          formatStringForCSV(offer.name),
          offer.asin,
          formatStringForCSV(offer.asinUrl),
          formatStringForCSV(offer.sellerName),
          formatStringForCSV(offer.sellerUrl),
          formatStringForCSV(offer.businessName),
          formatStringForCSV(offer.businessAddress),
          `${offer.buyBoxWinPercentage || 0}%`,
          offer.score,
          offer.price,
          offer.mapPrice,
          offer.diff,
        ]);
      }
      setExportData(csvData);
    }
  };

  const updateExportFilename = () => {
    let filename = `${region}-${insight?.description}`;
    filename = `${filename}-${getCurrentDateTime()}`;
    setExportFilename(`${filename}.csv`);
  };

  useEffect(() => {
    updateExportFilename();
    updateExport();
  }, [offers]);

  const headCells = [
    {
      id: 'sellerName',
      label: 'Seller',
      numeric: false,
      sortable: false,
      width: '15%',
      wide: true,
    },
    { id: 'name', label: 'Product', numeric: false, sortable: false, width: '25%', wide: true },
    {
      id: 'buyBoxWinPercentage',
      label: 'Win Rate',
      numeric: true,
      sortable: false,
      width: '10%',
      wide: false,
    },
    {
      id: 'score',
      label: 'Score',
      numeric: true,
      sortable: false,
      width: '8%',
      wide: false,
    },
    {
      id: 'price',
      label: 'Price',
      numeric: true,
      sortable: false,
      width: '8%',
      wide: false,
    },
    {
      id: 'mapPrice',
      label: 'MAP',
      numeric: true,
      sortable: false,
      width: '8%',
      wide: false,
    },
    {
      id: 'diff',
      label: 'Diff w/ MAP',
      numeric: true,
      sortable: false,
      width: '15%',
      wide: true,
    },
    { id: 'action', label: '', numeric: false, sortable: false, width: '5%', wide: true },
  ];

  const loadMore = () => {
    if (limit < offers.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > offers.length) {
        newLimit = offers.length;
      }
      setLimit(newLimit);
    }
  };

  const onShowSeller = (sellerId) => {
    setShowSellerId(sellerId);
  };

  const processData = (dataToProcess) => {
    const data = dataToProcess.getOfferInsight;
    const sorted = [].concat(data).sort((a, b) => a.sellerName.localeCompare(b.sellerName));
    setOffers(sorted);
    setLoading(false);
  };

  const GET_SELLER_INSIGHT_QUERY = gql`
    query GetOfferInsight($type: String!) {
      getOfferInsight(type: $type) {
        id
        offerId
        price
        mapPrice
        diff
        asin
        asinUrl
        sellerAuthorized
        globalAuth
        sellerId
        sellerName
        businessName
        businessAddress
        name
        imageUrl
        sellerUrl
        score
        buyBoxWinPercentage
        isBuyBoxWinner
        categoryId
        categoryName
        hasMultipleOffers
        harvestDate
      }
    }
  `;
  useQuery(GET_SELLER_INSIGHT_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !insight,
    variables: {
      type: insight?.type,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  if (open !== previousOpen) {
    setIsOpen(open);
    setPreviousOpen(open);
    setOffers([]);
    setLoading(true);
  }

  const headerContent = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        fontSize: '14px',
        fontWeight: '400',
        color: 'greys.silver',
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <Box>
        <b>Insight:</b> {insight?.description}
      </Box>
    </Box>
  );

  const changeChecked = (id) => {
    let newOffers = [];
    newOffers = offers.map((o) => (o.id === id ? { ...o, selected: !o.selected } : o));
    setOffers(newOffers);
    setSelectedCount(newOffers.filter((o) => o.selected === true).length);
  };

  const clearChecked = () => {
    let newOffers = [];
    newOffers = offers.map((o) => ({ ...o, selected: false }));
    setOffers(newOffers);
    setSelectedCount(0);
  };

  const handleSend = ({ email, letter }) => {
    const selected = offers.filter((o) => o.selected === true);
    if (email) {
      setEmailOffers(selected);
    } else if (letter) {
      setLetterOffers(selected);
    }
  };

  const getSellerIdCheckedOffers = () => {
    const selected = offers.filter((o) => o.selected === true);
    if (selected.length > 0) {
      return selected[0].sellerId;
    }
    return null;
  };

  const getSellerNameCheckedOffers = () => {
    const selected = offers.filter((o) => o.selected === true);
    if (selected.length > 0) {
      return selected[0].sellerId;
    }
    return null;
  };

  return (
    <Drawer
      data-cy="seller_insights_drawer"
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        width: wide ? wideDrawerWidth : drawerWidth,
        flexShrink: 0,
        borderTopLeftRadius: theme.borderRadius,
        borderBottomLeftRadius: theme.borderRadius,
        '& .MuiDrawer-paper': {
          width: wide ? wideDrawerWidth : drawerWidth,
          bgcolor: 'greys.white',
          overflow: 'hidden',
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
        },
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        {selectedCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              fullWidth: '100%',
              ml: 0,
              px: 2,
              pt: 1,
              pb: 1,
              backgroundColor: 'chips.purple.backgroundColor',
              height: '44px',
              borderTopLeftRadius: '6px',
              verticalAlign: 'middle',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  data-cy="seller_drawer_close_button"
                  onClick={() => {
                    clearChecked();
                  }}
                  size="large"
                >
                  <ClearIcon fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '14px',
                  fontWeight: '600',
                  ml: 1.5,
                  color: 'greys.silver',
                }}
              >
                {selectedCount} Selected{' '}
                <span style={{ fontWeight: 300, fontStyle: 'italic' }}>
                  (only offers from the same seller may be checked)
                </span>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '12px',
                  ml: 1.5,
                  color: 'greys.silver',
                }}
              >
                Bulk Enforcements:
              </Box>
              <Button
                size="small"
                variant="outlined"
                endIcon={<MailIcon fill={theme.palette.greys.darkGrey} />}
                sx={{
                  justifyContent: 'flex-start',
                  pl: 3,
                  pr: 3,
                  pt: '2px',
                  pb: '2px',
                  '&:hover': {
                    backgroundColor: 'chips.blue.backgroundColor',
                  },
                }}
                onClick={() => handleSend({ letter: true })}
              >
                Send Letter
              </Button>
              <Button
                size="small"
                variant="outlined"
                endIcon={<SendLetterIcon fill={theme.palette.greys.darkGrey} />}
                sx={{
                  justifyContent: 'flex-start',
                  pl: 3,
                  pr: 3,
                  pt: '2px',
                  pb: '2px',
                  '&:hover': {
                    backgroundColor: 'chips.blue.backgroundColor',
                  },
                }}
                onClick={() => handleSend({ email: true })}
              >
                Send Email
              </Button>
            </Box>
          </Box>
        )}
        {selectedCount === 0 && (
          <Box
            sx={{
              bgcolor: 'greys.backgroundGrey',
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              maxHeight: '44px',
              minHeight: '44px',
            }}
          >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  setwide(!wide);
                }}
                size="large"
              >
                <ExpandWindowIcon
                  style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                  fill={theme.palette.greys.silver}
                />
              </IconButton>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>{headerContent}</Box>
              {!loading && (
                <CSVLink target="_blank" filename={exportFilename} data={exportData}>
                  <Button
                    size="small"
                    sx={{ borderColor: 'greys.lightGrey', ml: 2 }}
                    variant="outlined"
                    startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                  >
                    Download
                  </Button>
                </CSVLink>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                data-cy="seller_insights_drawer_close_button"
                onClick={() => {
                  onClose();
                }}
                size="large"
              >
                <ClearIcon fill={theme.palette.greys.silver} />
              </IconButton>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            borderRadius: theme.borderRadius,
            bgcolor: 'greys.backgroundGrey',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 44px)',
          }}
        >
          {loading && <Loading />}
          {!loading && (
            <Scrollable transparent sx={{ borderRadius: '0' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={true || false}
                loader={limit < offers.length && <Loading key={0} />}
                useWindow={false}
              >
                <TableContainer sx={{ overflowX: 'initial' }}>
                  <Table data-cy="seller_insights_drawer_table" stickyHeader>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderTop: `1px solid ${theme.palette.greys.lighterGrey}`,
                          borderLeft: `1px solid ${theme.palette.greys.lighterGrey}`,
                          borderRight: `1px solid ${theme.palette.greys.lighterGrey}`,
                        }}
                      >
                        {headCells
                          .filter((c) => wide || c.wide)
                          .map((headCell) => (
                            <TableCell key={headCell.id} width={headCell.width}>
                              {headCell.label}
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {offers.slice(0, limit).map((offer, index) => (
                        <InsightsOfferRow
                          key={offer.id}
                          offer={offer}
                          index={index}
                          onShowSeller={onShowSeller}
                          compact={!wide}
                          changeChecked={changeChecked}
                          selectedSellerId={getSellerIdCheckedOffers()}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </Scrollable>
          )}
        </Box>
      </Box>
      {letterOffers && (
        <LetterEditor
          isOpen
          sellerId={getSellerIdCheckedOffers()}
          sellerName={getSellerNameCheckedOffers}
          offers={letterOffers}
          sendMode
          onClose={(sent) => {
            if (sent) {
              clearChecked();
            }
            setLetterOffers(null);
          }}
        />
      )}
      {emailOffers && (
        <LetterEditor
          isOpen
          sellerId={getSellerIdCheckedOffers()}
          sellerName={getSellerNameCheckedOffers}
          offers={emailOffers}
          emailMode
          sellerEmail="testing@gmail.com"
          onClose={(sent) => {
            if (sent) {
              clearChecked();
            }
            setEmailOffers(null);
          }}
        />
      )}
      <SellerDrawer
        id={showSellerId}
        open={showSellerId != null}
        onClose={() => {
          setShowSellerId(null);
        }}
        large
      />
    </Drawer>
  );
};

OfferInsightsDrawer.propTypes = {
  insight: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

OfferInsightsDrawer.defaultProps = {
  open: false,
  insight: null,
};

export default OfferInsightsDrawer;
