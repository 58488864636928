import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Scrollable,
  Loading,
  Highlights,
  DataHighlight,
  NonScrollable,
  TabPanel,
  FeatureTestDialog,
  LoadingButton,
} from 'components';
import axios from 'axios';
import { ReactComponent as HighlightBuyBoxWinIcon } from 'assets/highlight-buy-box-win.svg';
import { ReactComponent as HighlightNegativeReviewsIcon } from 'assets/highlight-negative-reviews.svg';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { ReactComponent as HighlightNumberOfSellersIcon } from 'assets/highlight-number-of-sellers.svg';
import { ReactComponent as HighlightInventoryTotalIcon } from 'assets/highlight-inventory-total.svg';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as StarIcon } from 'assets/star.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as SendLetterIcon } from 'assets/send-letter.svg';
import SellerAuthorizationState from 'pages/Common/SellerAuthorizationState';
import Geocode from 'react-geocode';
import {
  Button,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  Checkbox,
  TableRow,
  TableHead,
  TableCell,
  TableSortLabel,
  Typography,
  MenuItem,
  IconButton,
  Link,
  Menu,
  Tooltip,
  Box,
  FormControl,
  useTheme,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import addressService from 'services/address-service';
import { gql, useQuery } from '@apollo/client';
import { ToastDataContext, AuthDataContext, RegionDataContext } from 'contexts';
import { CSVLink } from 'react-csv';
import { getCurrentDateTime } from 'utils/dates';
import { SCORE_GOOD, SCORE_MARGINAL } from 'utils/constants';
import { foundKeywords, formatStringForCSV } from 'utils/strings';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import LetterEditor from 'pages/Common/LetterEditor';
import ChangeAuthSeller from 'pages/Common/ChangeAuthSeller';
import AmazonRegionIcon from 'components/AmazonRegionIcon/AmazonRegionIcon';
import HealthDonut from './HealthDonut';
import SellerOfferRow from './SellerOfferRow';
import ActionContent from './ActionContent';
import OtherWinners from './OtherWinners';
import OtherWinnersDrawer from './OtherWinnersDrawer';
import AuthorizedAsins from './AuthorizedAsins';
import Notes from './Notes';
import HistoricalAsins from './HistoricalAsins';

const StreetView = ({ streetView }) => (
  <Box>
    {streetView && streetView.available && (
      <Link href={streetView.linkUrl} target="_blank" underline="hover">
        <Box
          component="img"
          alt="street view"
          src={streetView.url}
          sx={{
            maxHeight: '145px',
            minHeight: '145px',
            borderRadius: (theme) => theme.borderRadius,
          }}
        />
      </Link>
    )}
    {streetView && !streetView.available && (
      <Box
        component="iframe"
        title="Map"
        src={streetView.url}
        sx={{ maxHeight: '145px', minHeight: '145px', borderRadius: (theme) => theme.borderRadius }}
      />
    )}
  </Box>
);

const SellerContent = ({ id, onSelectOfferData, hide, sx, compact }) => {
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [seller, setSeller] = useState(null);
  const [streetView, setStreetView] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [scoreSpread, setScoreSpread] = useState({ good: 0, bad: 0, ugly: 0 });
  const [tab, setTab] = useState(0);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const [asins, setAsins] = useState([]);
  const [inventoryTotal, setInventoryTotal] = useState(0);
  const [processedAsins, setProcessedAsins] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [policyTestOpen, setPolicyTestOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullAuthMenuAnchorEl, setFullAuthMenuAnchorEl] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const { regionUrl, regionCurrency } = useContext(RegionDataContext);
  const [hideSearch, setHideSearch] = useState(false);
  const [hideDownload, setHideDownload] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [showAsin, setShowAsin] = useState(null);
  const { account, hasAbility, region, hasPermission } = useContext(AuthDataContext);
  const [selected, setSelected] = useState([]);
  const [letterOffers, setLetterOffers] = useState(null);
  const [emailOffers, setEmailOffers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [winRateLoading, setWinRateLoading] = useState(true);
  const [winRate, setWinRate] = useState(null);
  const [lostSalesLoading, setLostSalesLoading] = useState(true);
  const [lostSales, setLostSales] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const theme = useTheme();

  const processAddress = async (sellerResult) => {
    let { businessAddress } = sellerResult;
    try {
      if (sellerResult) {
        if (sellerResult.businessDetails) {
          const verifiedAddressResult = await addressService.getVerifiedAddress({
            address: sellerResult.businessAddress,
          });
          if (verifiedAddressResult?.deliverable) {
            setVerifiedAddress(verifiedAddressResult);
          }
        }
        if (sellerResult?.businessName) {
          setBusinessName(sellerResult?.businessName)
        }
        if (
          sellerResult.businessAddress?.includes('Business Name:') ||
          !sellerResult.businessAddress
        ) {
          businessAddress = null;
        } else {
          Geocode.fromAddress(businessAddress).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              fetch(
                `https://maps.googleapis.com/maps/api/streetview/metadata?size=640x640&location=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
              )
                .then((res) => res.json())
                .then((data) => {
                  const available = data.status === 'OK';
                  if (available) {
                    setStreetView({
                      lat,
                      lng,
                      available,
                      url: `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                      linkUrl: `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                    });
                  } else {
                    setStreetView({
                      lat,
                      lng,
                      available,
                      url: `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
                        businessAddress,
                      )}&center=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                    });
                  }
                });
            },
            (error) => {
              console.error(error);
            },
          );
        }
      }
    } catch (error) {
      setToast({ type: 'fatal', message: error.message });
    } finally {
      setLoadingAddress(false);
    }
  };

  const isAsinAuthorized = ({ asin, list, categoryList }) => {
    const authorizedInIndividualList = list?.map((a) => a.asin).includes(asin.asin);
    const authorizedInCategory = categoryList
      ?.filter((c) => c.authorized)
      .map((c) => c.id)
      .includes(asin.categoryId);
    return authorizedInCategory || authorizedInIndividualList;
  };


  const headCells = [
    { id: 'checkbox', label: '', numeric: false, sortable: false, width: '2%', align: 'left', compact: true },
    {
      id: 'name',
      label: 'Product',
      numeric: false,
      sortable: true,
      width: '40%',
      align: 'left',
      defaultSort: 'asc',
      compact: true,
    },
    {
      id: 'isAuthorized',
      label: 'Authorized',
      numeric: true,
      sortable: true,
      width: '7%',
      align: 'left',
      defaultSort: 'asc',
      compact: true,
    },
    {
      id: 'asin',
      label: 'ASIN',
      numeric: false,
      sortable: true,
      width: '7%',
      align: 'left',
      defaultSort: 'asc',
      compact: false,
    },
    {
      id: 'price',
      label: 'Price',
      numeric: true,
      sortable: true,
      width: '7%',
      align: 'right',
      defaultSort: 'asc',
      compact: false,
    },
    {
      id: 'mapPrice',
      label: 'MAP',
      numeric: true,
      sortable: true,
      width: '7%',
      align: 'right',
      compact: false,
    },
    {
      id: 'buyBoxWinPercentage',
      label: 'Win Rate',
      numeric: true,
      sortable: true,
      width: '7%',
      align: 'right',
      defaultSort: 'desc',
      compact: false,
    },
    {
      id: 'stockEstimation',
      label: 'Inventory',
      numeric: true,
      sortable: true,
      width: '7%',
      align: 'right',
      defaultSort: 'desc',
      compact: false,
    },
    {
      id: 'currentScore',
      label: 'Score',
      numeric: true,
      sortable: true,
      width: '7%',
      align: 'right',
      defaultSort: 'desc',
      compact: true,
    },
    { id: 'action', label: '', numeric: false, sortable: false, width: '2%', compact: true },
  ];


  const sortData = (dataToProcess, column, order) => {
    const isAsc = order === 'asc';
    let isNumeric = false;
    for (let i = 0; i < headCells.length; i += 1) {
      if (headCells[i].id === column) {
        isNumeric = headCells[i].numeric;
        break;
      }
    }
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (column === 'currentScore') {
        if (a[column].score === b[column].score && a.name !== null && b.name !== null) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column].score - a[column].score;
        }
        return a[column].score - b[column].score;
      }
      if (isNumeric) {
        if (a[column] === b[column] && a.name !== null && b.name !== null) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column] - a[column];
        }
        return a[column] - b[column];
      }
      if (a[column] !== null && b[column] !== null) {
        if (!isAsc) {
          return b[column].localeCompare(a[column]);
        }
        return a[column].localeCompare(b[column]);
      }
      return 0;
    });
    return sorted;
  };

  const filterAndSortAsins = (asinsToFilter, searchKeywords = null,  authorizedList, categoryList, column, order) => {
    const asinsToShow = [];
    for (let i = 0; i < asinsToFilter.length; i += 1) {
      const asinToAdd = cloneDeep(asinsToFilter[i]);
      if (foundKeywords(asinToAdd.objString, searchKeywords)) {
        asinToAdd.isAuthorized = isAsinAuthorized({
          asin: asinToAdd,
          list: authorizedList,
          categoryList,
        });
        asinsToShow.push(asinToAdd);
      }
    }
    const sortedData = sortData(asinsToShow, column, order);
    setProcessedAsins(sortedData);
    setDataToExport(sortedData);
    return asinsToShow;
  };

  const processData = (dataToProcess) => {
    const sellerResult = cloneDeep(dataToProcess.getSeller);
    setCategories(dataToProcess.getSeller?.categories);
    sellerResult.categories = sellerResult.categories.filter((c) =>
      sellerResult.offers.some((a) => a.categoryId === c.id),
    );
    processAddress(sellerResult);
    setSeller(sellerResult);
    const searchifiedAsins = sellerResult.offers.map((a) => ({
      objString: [a.name, a.asin, a.variantTitle, a.parentAsin].join(' '),
      ...a,
    }));
    setAsins(searchifiedAsins);
    filterAndSortAsins(
      searchifiedAsins,
      null,
      sellerResult.authorizedAsins,
      dataToProcess.getSeller?.categories,
      sortColumn, sortOrder,
    );
    let good = 0;
    let bad = 0;
    let ugly = 0;
    let inventory = 0;
    for (let i = 0; i < sellerResult?.offers?.length; i += 1) {
      const asin = sellerResult.offers[i];
      if (account?.fetchInventory && asin.stockEstimation) {
        inventory += asin.stockEstimation;
      }
      if (asin.currentScore) {
        const { score } = asin.currentScore;
        if (score >= SCORE_GOOD) {
          good += 1;
        } else if (score > SCORE_MARGINAL) {
          bad += 1;
        } else {
          ugly += 1;
        }
      }
    }
    setScoreSpread({ good, bad, ugly });
    setInventoryTotal(inventory);
    setLoading(false);
  };

  const GET_SELLER_QUERY = gql`
    query GetSellerContent($id: String!) {
      getSeller(id: $id) {
        id
        coordinates
        dataSource
        harvestDate
        name
        sellerUrl,
        storeLink
        logo
        rating
        totalRatings
        totalRatingsPercentage
        businessDetails
        businessAddress
        businessName
        positiveThirty
        neutralThirty
        negativeThirty
        countThirty
        positiveNinety
        neutralNinety
        negativeNinety
        countNinety
        positiveTwelveMonths
        neutralTwelveMonths
        negativeTwelveMonths
        countTwelveMonths
        positiveLifetime
        neutralLifetime
        negativeLifetime
        countLifetime
        authorized
        actionsTaken
        firstDetected
        systemTags
        categories {
          uniqueId
          id
          name
          authorized
        }
        globalAuth
        currentScore {
          score
          harvestDate
        }
        authorizedAsins {
          asin
          name
          categoryId
          imageUrl
        }
        offers {
          asin
          asinUrl
          sellerId
          categoryId
          buyBoxWinPercentage
          authorizedSellerExists
          name
          offerId
          price
          mapPrice
          parentAsin
          variantTitle
          fulfilledByAmazon
          shippingPrice
          image
          stockEstimation
          harvested
          harvestDate
          categoryId
          categoryName
          currentScore {
            score
          }
        }
      }
    }
  `;

  const { data, refetch } = useQuery(GET_SELLER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { id },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_SELLER_WIN_RATE_QUERY = gql`
    query GetSellerWinRate($sellerId: String!, $dateStart: DateTime, $dateEnd: DateTime) {
      getWinRateAverageForSellers(sellerId: $sellerId, dateStart: $dateStart, dateEnd: $dateEnd) {
        sellerId
        winRate
      }
    }
  `;

  const GET_LIFETIME_LOST_SALES = gql`
    query GetSellerWinRate($sellerId: String!) {
      getLifetimeLostSales(sellerId: $sellerId)
    }
  `;

  const processLostSales = (dataToProcess) => {
    if (dataToProcess) {
      const sales = dataToProcess.getLifetimeLostSales;
      setLostSales(sales);
      setLostSalesLoading(false);
    }
  };

  useQuery(GET_LIFETIME_LOST_SALES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { sellerId: id },
    onCompleted: processLostSales,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processWinRate = (dataToProcess) => {
    if (dataToProcess) {
      const rate = dataToProcess.getWinRateAverageForSellers;
      if (rate.length > 0) {
        setWinRate(rate[0].winRate);
      }
      setWinRateLoading(false);
    }
  };

  const { dateStart, dateEnd } = (() => {
    const date = new Date();
    const startDate = dayjs(date).add(-29, 'day').startOf('day').toDate();
    const endDate = dayjs(date).endOf('day').toDate();
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    return { dateStart: start, dateEnd: end };
  })();

  useQuery(GET_SELLER_WIN_RATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { sellerId: id, dateStart, dateEnd },
    onCompleted: processWinRate,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFullAuthMenu = (event) => {
    setFullAuthMenuAnchorEl(event.currentTarget);
  };

  const handleFullAuthMenuClose = () => {
    setFullAuthMenuAnchorEl(null);
  };

  // eslint-disable-next-line max-len
  const policyFeatureDescription = `We are evaluating clients' interest in this feature.  We plan to offer users the ability to submit Seller Policy Violations through Amazon.  Click I'm Interested to let us know we should continue development on this feature.`;

  const handlePolicyTestClick = () => {
    handleMenuClose();
    setPolicyTestOpen(true);
  };

  const handlePolicyTestClose = () => {
    setPolicyTestOpen(false);
  };

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  }, []);

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const handleTabChange = (newValue) => {
    setTab(newValue);
    if (newValue === 1 || newValue === 2 || newValue === 4) {
      setHideSearch(true);
    } else {
      setHideSearch(false);
    }
    if (newValue === 1 || newValue === 2 || newValue === 4 || newValue === 5) {
      setHideDownload(true);
    } else {
      setHideDownload(false);
    }
  };

  const handleAuthChanged = () => {
    refetch();
  };

  const exportAsins = (asinsToExport) => {
    if (!region) {
      return [];
    }
    const csvData = [
      [
        'ASIN',
        'ProductLink',
        'SellerID',
        'SellerName',
        'SellerLink', 
        'BusinessName', 
        'BusinessAddress',
        'Price',
        'Win Rate',
        'Name',
        'Variant',
        'Shipping',
        'Score',
        'Fulfilled By Amazon',
      ],
    ];
    if (account?.fetchInventory) {
      csvData[0].push('Inventory');
    }
    for (let i = 0; i < asinsToExport.length; i += 1) {
      const item = asinsToExport[i];
      if (!item.parent) {
        const { price } = item;
        const shipping = item.shippingPrice;
        const { score } = item.currentScore;
        const dataArray = [
          item.asin,
          formatStringForCSV(item.asinUrl),
          formatStringForCSV(item.name),
          item.sellerId,
          formatStringForCSV(seller.sellerUrl),
          formatStringForCSV(seller.businessName),
          formatStringForCSV(seller.businessAddress),
          price
            ? Intl.NumberFormat(region, {
                style: 'currency',
                currency: regionCurrency,
              }).format(price?.toFixed(2))
            : 0,
          item.buyBoxWinPercentage ? `${item.buyBoxWinPercentage}%` : '',
          formatStringForCSV(item.name),
          formatStringForCSV(item.variantTitle),
          shipping
            ? Intl.NumberFormat(region, {
                style: 'currency',
                currency: regionCurrency,
              }).format(shipping?.toFixed(2))
            : 0,
          score || 0,
          item?.fulfilledByAmazon ? `Yes` : `No`,
        ];
        if (account?.fetchInventory) {
          dataArray.push(item?.stockEstimation > 0 ? item?.stockEstimation : '');
        }
        csvData.push(dataArray);
      }
    }
    return csvData;
  };

  const onSearch = (searchText) => {
    setSearch(searchText);
    filterAndSortAsins(asins, searchText, seller.authorizedAsins, categories, sortColumn, sortOrder);
  };

  const loadMore = async () => {
    if (limit < processedAsins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > processedAsins.length) {
        newLimit = processedAsins.length;
      }
      setLimit(newLimit);
    }
  };

  const isSelected = (asin) => selected.indexOf(asin) !== -1;

  const handleCheckAll = (event) => {
    if (event.target.checked) {
      setSelected([].concat(processedAsins));
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, asin) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(asin);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, asin);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSend = ({ email, letter }) => {
    if (email) {
      setEmailOffers(selected);
    } else if (letter) {
      setLetterOffers(selected);
    }
  };

  const combinedLoading = loading || loadingPdf;

  const savePDF = async () => {
    setLoadingPdf(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_REPORTS_URL}/report`,
        {
          report: 'seller_profile',
          accountId: account.id,
          dateStart,
          dateEnd,
          sellerId: id,
          regionId: region,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          responseType: 'blob',
          timeout: 60000,
        },
      );

      const filename = result.headers.filename;
      const href = URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoadingPdf(false);
    } catch (err) {
      console.error(err.message);
    }
  };

  const sortHandler = (headCell, dataToProcess, order) => {
    if (!headCell.sortable) {
      return;
    }
    const sortingOrder = sortColumn !== headCell.id ? headCell.defaultSort : order;
    setSortOrder(sortingOrder);
    setSortColumn(headCell.id);
    filterAndSortAsins(dataToProcess, search, seller.authorizedAsins, categories, headCell.id, sortingOrder);
  };

  return (
    <>
      {!hide && (
        <>
          <NonScrollable sx={{ pt: 0, pb: 2, ...sx }}>
            {loading && <Loading />}
            {!loading && seller && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'start',
                      justifyContent: 'space-between',
                      width: '100%',
                      minHeight: '152px',
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                      <Box>
                        <HealthDonut
                          title="Health Index"
                          value={seller?.currentScore?.score}
                          good={scoreSpread.good}
                          bad={scoreSpread.bad}
                          ugly={scoreSpread.ugly}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Box>
                              <AmazonRegionIcon size="24" />
                            </Box>
                            <Box
                              sx={{ fontSize: '24px', fontWeight: '700' }}
                              data-cy="seller_name_content"
                            >
                              {seller.name}
                            </Box>
                            {compact && (
                              <SellerAuthorizationState
                                globalAuth={seller.globalAuth}
                                partialAuth={seller.authorized}
                              />
                            )}
                            {compact && (
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton size="small" variant="outlined" onClick={handleMenu}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleMenuClose}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        bgcolor: 'greys.backgroundGrey',
                                        textTransform: 'uppercase',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        p: '4px 25px',
                                        color: 'greys.silver',
                                      }}
                                    >
                                      Seller
                                    </Typography>
                                    <MenuItem
                                      onClick={() => {
                                        handleMenuClose();
                                        window.open(
                                          `${regionUrl}/sp?_encoding=UTF8&seller=${seller.id}`,
                                        );
                                      }}
                                    >
                                      Visit Seller
                                    </MenuItem>
                                  </Box>
                                  {seller.offers.length > 0 && seller.categories.length > 0 && (
                                    <ChangeAuthSeller
                                      onAuthorizedChanged={handleAuthChanged}
                                      onClose={handleMenuClose}
                                      seller={seller}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      width: '100%',
                                      m: 0,
                                      p: 0,
                                      borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      bgcolor: 'greys.backgroundGrey',
                                      textTransform: 'uppercase',
                                      fontSize: '12px',
                                      fontWeight: '600',
                                      p: '4px 25px',
                                      color: 'greys.silver',
                                    }}
                                  >
                                    Enforcements
                                  </Typography>
                                  <MenuItem onClick={handlePolicyTestClick}>
                                    Seller Policy Violation
                                  </MenuItem>
                                </Menu>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box sx={{ minHeight: '55px' }}>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Box>
                              <LocationIcon fill={theme.palette.greys.grey} />
                            </Box>
                            <Box sx={{ fontSize: '14px', fontWeight: '400' }}>
                              {loadingAddress && <Loading small />}
                              {!loadingAddress && (
                                <>
                                  {verifiedAddress ? (
                                    <Box sx={{ textTransform: 'uppercase' }}>
                                      {businessName && (
                                        <b>
                                          {businessName}
                                          <br />
                                        </b>
                                      )}
                                      <Box sx={{ fontSize: '12px' }}>
                                        {verifiedAddress.addressLine1}
                                        <br />
                                        {verifiedAddress.addressLine2?.length > 0 && (
                                          <Box>
                                            <br />
                                            {verifiedAddress.addressLine2}
                                          </Box>
                                        )}
                                        {verifiedAddress.city} {verifiedAddress.state}
                                        {', '}
                                        {verifiedAddress.zip}
                                        {', '}
                                        {verifiedAddress.country}
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box sx={{ textTransform: 'uppercase' }}>
                                      {businessName && (
                                        <b>
                                          {businessName}
                                          <br />
                                        </b>
                                      )}
                                      {seller.businessAddress || 'Not Available'}
                                    </Box>
                                  )}
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        {compact && <StreetView streetView={streetView} />}
                        {/* {!compact && seller.offers.length > 0 && seller.categories.length > 0 && ( */}
                        {!compact && (
                          <Box sx={{ display: 'flex', gap: 2, alignItems: 'stretch' }}>
                            <Button
                              sx={{ borderColor: 'greys.lightGrey' }}
                              variant="outlined"
                              onClick={() => {
                                window.open(`${regionUrl}/sp?_encoding=UTF8&seller=${seller.id}`);
                              }}
                            >
                              Visit Seller
                            </Button>
                            <LoadingButton
                              loading={loadingPdf}
                              sx={{ borderColor: 'greys.lightGrey' }}
                              variant="outlined"
                              disabled={combinedLoading}
                              startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                              onClick={savePDF}
                              data-cy="seller_download_profile"
                            >
                              Download Profile
                            </LoadingButton>
                            {seller.offers.length > 0 && seller.categories.length > 0 && (
                              <>
                                <Button
                                  sx={{ borderColor: 'greys.lightGrey' }}
                                  variant="outlined"
                                  onClick={handleFullAuthMenu}
                                >
                                  {seller.globalAuth && (
                                    <Tooltip title="Seller is authorized for all current and new categories that contain the seller's asins.">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          height: '24px',
                                          fontWeight: '600',
                                        }}
                                        data-cy="authorized_seller_button"
                                      >
                                        Authorized Seller
                                        <StarIcon />
                                      </Box>
                                    </Tooltip>
                                  )}
                                  {!seller.globalAuth && seller.authorized && (
                                    <Tooltip title="Seller is authorized for at least 1 category that contains the seller's asins or authorized for at least 1 individual asin.">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          height: '24px',
                                          fontWeight: '600',
                                        }}
                                        data-cy="authorized_seller_button"
                                      >
                                        Authorized Seller
                                        <StarIcon fill={theme.palette.chips.purple.color} />
                                      </Box>
                                    </Tooltip>
                                  )}
                                  {!seller.authorized && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '24px',
                                        fontWeight: '600',
                                      }}
                                      data-cy="not_authorized_seller_button"
                                    >
                                      Not Authorized
                                    </Box>
                                  )}
                                  <CaretDown />
                                </Button>
                                {fullAuthMenuAnchorEl && (
                                  <Menu
                                    anchorEl={fullAuthMenuAnchorEl}
                                    keepMounted
                                    open={Boolean(fullAuthMenuAnchorEl)}
                                    onClose={handleFullAuthMenuClose}
                                  >
                                    <FormControl fullWidth>
                                      <ChangeAuthSeller
                                        onClose={handleFullAuthMenuClose}
                                        seller={seller}
                                        onAuthorizedChanged={handleAuthChanged}
                                      />
                                    </FormControl>
                                  </Menu>
                                )}
                              </>
                            )}
                            <Box sx={{ minHeight: '100%' }}>
                              <Button
                                sx={{
                                  borderColor: 'greys.lightGrey',
                                  p: 0,
                                  maxWidth: '40px',
                                  minWidth: '40px',
                                }}
                                variant="outlined"
                                onClick={handleMenu}
                              >
                                <MoreHorizIcon />
                              </Button>
                              <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                              >
                                <MenuItem onClick={handlePolicyTestClick}>
                                  Seller Policy Violation
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {!compact && <StreetView streetView={streetView} />}
                  </Box>
                </Box>
                <Highlights>
                  <DataHighlight
                    loading={winRateLoading}
                    icon={<HighlightBuyBoxWinIcon />}
                    title="30-Day Win Rate"
                    tip="The percentage of the time this seller has won the buy box over the last 30 days for attached ASINs that are not suppressed"
                    value={winRate !== null ? `${winRate}%` : `N/A`}
                  />
                  <DataHighlight
                    loading={lostSalesLoading}
                    icon={<PaidRoundedIcon sx={{color: '#881CE0'}} />}
                    title="Lifetime Lost Sales"
                    tip="The estimated lifetime lost sales for this seller"
                    value={Intl.NumberFormat(region, {
                      style: 'currency',
                      currency: regionCurrency,
                      maximumFractionDigits: 0
                    }).format(lostSales?.toFixed(0))}
                  />
                  {!compact && (
                    <DataHighlight
                      icon={<HighlightNumberOfSellersIcon />}
                      title="Offers"
                      value={seller?.offers?.length?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      datacy="data_highlight_offers"
                    />
                  )}
                  {seller && !compact && (
                    <DataHighlight
                      icon={<HighlightNegativeReviewsIcon />}
                      title="Negative Reviews"
                      value={`${seller?.negativeLifetime || 0}%`}
                      datacy="data_highlight_negative_reviews"
                    />
                  )}
                  {account.fetchInventory && !compact && (
                    <DataHighlight
                      icon={<HighlightInventoryTotalIcon />}
                      title="Inventory"
                      value={inventoryTotal.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    />
                  )}
                </Highlights>
                <Box>
                  {selected.length === 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        height: '44px',
                      }}
                    >
                      <Tabs
                        sx={{ mt: 3 }}
                        color="primary"
                        value={tab}
                        onChange={(_event, value) => handleTabChange(value)}
                      >
                        <Tab label="Offers" data-cy="offers_tab" />
                        <Tab label="ASIN Assignments" data-cy="asinassignment_tab" />
                        <Tab label="Seller Details" data-cy="notes_tab" />
                        <Tab label="Other Winners" data-cy="otherwinners_tab" />
                        <Tab label="Action History" data-cy="actionhistory_tab" />
                        {hasPermission('tenantManagement') && (
                          <Tab label="Active/Removed Offers" data-cy="active_inactive_tab" />
                        )}
                      </Tabs>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'stretch' }}>
                        {!hideSearch && (
                          <TextField
                            onChange={(event) => onSearch(event.target.value)}
                            multiline
                            placeholder="Search"
                            sx={{ width: '26ch' }}
                            value={search}
                            InputProps={{
                              sx: { pl: 1, minHeight: '100%', height: '100%' },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon fill={theme.palette.greys.silver} />
                                </InputAdornment>
                              ),
                              endAdornment: search ? (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      onSearch('');
                                    }}
                                    size="large"
                                  >
                                    <ClearIcon fill={theme.palette.greys.silver} />
                                  </IconButton>
                                </InputAdornment>
                              ) : (
                                ''
                              ),
                            }}
                          />
                        )}
                        {!hideDownload && (
                          <CSVLink
                            target="_blank"
                            filename={`${region}-SellerAsinExport-${seller.id
                              }-${getCurrentDateTime()}.csv`}
                            data={exportAsins(dataToExport)}
                          >
                            <Button
                              sx={{ borderColor: 'greys.lightGrey' }}
                              variant="outlined"
                              startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
                            >
                              Download
                            </Button>
                          </CSVLink>
                        )}
                      </Box>
                    </Box>
                  )}
                  {selected.length > 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        fullWidth: '100%',
                        px: 2,
                        py: 1,
                        backgroundColor: 'chips.purple.backgroundColor',
                        height: '44px',
                        borderRadius: '6px',
                        verticalAlign: 'middle',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            data-cy="seller_drawer_close_button"
                            onClick={() => {
                              setSelected([]);
                            }}
                            size="large"
                          >
                            <ClearIcon fill={theme.palette.greys.silver} />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '14px',
                            fontWeight: '600',
                            ml: 1.5,
                            color: 'greys.silver',
                          }}
                        >
                          {selected.length} Selected
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '12px',
                            ml: 1.5,
                            color: 'greys.silver',
                          }}
                        >
                          Bulk Enforcements:
                        </Box>
                        {hasAbility('sendLetter') && (
                          <Button
                            size="small"
                            variant="outlined"
                            endIcon={<MailIcon fill={theme.palette.greys.darkGrey} />}
                            sx={{
                              justifyContent: 'flex-start',
                              pl: 3,
                              pr: 3,
                              pt: '2px',
                              pb: '2px',
                              '&:hover': {
                                backgroundColor: 'chips.blue.backgroundColor',
                              },
                            }}
                            onClick={() => handleSend({ letter: true })}
                          >
                            Send Letter
                          </Button>
                        )}
                        {hasAbility('sendEmail') && (
                          <Button
                            size="small"
                            variant="outlined"
                            endIcon={<SendLetterIcon fill={theme.palette.greys.darkGrey} />}
                            sx={{
                              justifyContent: 'flex-start',
                              pl: 3,
                              pr: 3,
                              pt: '2px',
                              pb: '2px',
                              '&:hover': {
                                backgroundColor: 'chips.blue.backgroundColor',
                              },
                            }}
                            onClick={() => handleSend({ email: true })}
                          >
                            Send Email
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </NonScrollable>
          <Scrollable>
            <TabPanel index={0} value={tab}>
              {!loading && seller && (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadMore}
                  hasMore
                  loader={limit < processedAsins.length && <Loading key={0} />}
                  useWindow={false}
                >
                  <TableContainer sx={{ overflowX: 'initial' }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          {headCells.filter(c => (compact && c.compact === true || !compact)).map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              sortDirection={sortColumn === headCell.id ? sortOrder : false}
                              width={headCell.width}
                              align={headCell.align}
                            >
                              {headCell.id === 'checkbox' && (
                                <Box>
                                  {(hasAbility('sendLetter') || hasAbility('sendEmail')) && (
                                    <Checkbox
                                      indeterminate={
                                        selected.length > 0 && selected.length < processedAsins.length
                                      }
                                      checked={selected.length === processedAsins.length}
                                      onChange={handleCheckAll}
                                    />
                                  )}
                                </Box>
                              )}
                              {headCell.id !== 'checkbox' && (
                                <TableSortLabel
                                  hideSortIcon={!headCell.sortable}
                                  data-cy={headCell.label}
                                  active={headCell.sortable && sortColumn === headCell.id}
                                  direction={
                                    sortColumn === headCell.id ? sortOrder : headCell.defaultSort ?? 'asc'
                                  }
                                  onClick={() => {
                                    sortHandler(
                                      headCell,
                                      processedAsins,
                                      sortOrder === 'asc' ? 'desc' : 'asc',
                                    );
                                  }}
                                >
                                  {headCell.label}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {processedAsins.slice(0, limit).map((asin, index) => (
                          <SellerOfferRow
                            selected={isSelected(asin)}
                            compact={compact}
                            onCheckboxClicked={handleCheckboxClick}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${asin.asin}-${asin.parentAsin}-${asin.parent ? 1 : 0}-${index}`}
                            offer={asin}
                            seller={seller}
                            onSelectOffer={(offer) =>
                              onSelectOfferData({ seller, offerId: offer.offerId, asin: asin.asin })
                            }
                            onShowWinRateAsin={(a) => setShowAsin(a)}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </InfiniteScroll>
              )}
            </TabPanel>
            <TabPanel index={1} value={tab}>
              {seller && <AuthorizedAsins seller={seller} onUpdatedAsinAuthorization={refetch} />}
            </TabPanel>
            <TabPanel index={2} value={tab}>
              {seller && <Notes seller={seller} />}
            </TabPanel>
            <TabPanel index={3} value={tab}>
              <OtherWinners sellerId={id} search={search} setDataToExport={setDataToExport} />
            </TabPanel>
            <TabPanel index={4} value={tab}>
              <ActionContent sellerId={id} />
            </TabPanel>
            {hasPermission('tenantManagement') && (
              <TabPanel index={5} value={tab}>
                <HistoricalAsins sellerId={id} search={search} onChanged={refetch} />
              </TabPanel>
            )}
          </Scrollable>
          <FeatureTestDialog
            featureDescription={policyFeatureDescription}
            featureTitle="Seller Profile Violation"
            featureTestOpen={policyTestOpen}
            handleClose={handlePolicyTestClose}
          />
          <OtherWinnersDrawer
            asin={showAsin}
            sellerId={id}
            open={!!showAsin}
            onClose={() => setShowAsin(null)}
          />
          {letterOffers && (
            <LetterEditor
              isOpen
              sellerId={seller.id}
              sellerName={seller.name}
              offers={letterOffers}
              sendMode
              onClose={(sent) => {
                if (sent) {
                  setSelected([]);
                }
                setLetterOffers(null);
              }}
            />
          )}
          {emailOffers && (
            <LetterEditor
              isOpen
              sellerId={seller.id}
              sellerName={seller.name}
              offers={emailOffers}
              emailMode
              sellerEmail="testing@gmail.com"
              onClose={(sent) => {
                if (sent) {
                  setSelected([]);
                }
                setEmailOffers(null);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

SellerContent.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.string.isRequired,
  onSelectOfferData: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  compact: PropTypes.bool,
};

SellerContent.defaultProps = {
  hide: false,
  compact: false,
  sx: null,
};

export default SellerContent;
