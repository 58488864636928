import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  tableCellClasses,
  tableRowClasses,
  Tooltip,
  Box,
  styled,
} from '@mui/material';
import { Loading, FilterDropdown, LinkButton } from 'components';
import { ToastDataContext, AuthDataContext, RegionDataContext } from 'contexts';
import { getCurrentDateTime } from 'utils/dates';
import { CSVLink } from 'react-csv';
import { formatStringForCSV } from 'utils/strings';
import { ReactComponent as Download } from 'assets/download.svg';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import InfiniteScroll from 'react-infinite-scroller';
import OffersDrawer from 'pages/Common/OffersDrawer';
import { gql, useLazyQuery } from '@apollo/client';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import WidgetHeader from './WidgetHeader';
import Widget from './Widget';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.greys.silver,
    backgroundColor: theme.palette.greys.white,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '13px',
    fontWeight: '400',
    borderLeft: 'none',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.root}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}}`,
    '&:last-child': {
      paddingRight: theme.spacing(3),
    },
    '&:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.greys.backgroundGrey,
    },
    border: 'none',
  },
}));

const StyledTableHeaderRow = styled(TableRow)(() => ({
  [`&.${tableRowClasses.root}`]: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
}));

const HoverAsin = ({ asin }) => {
  const { region } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  return (
    <Box sx={{ p: '20px', display: 'flex', gap: 2 }}>
      <Box>
        {asin.imageUrl && (
          <Box
            component="img"
            src={`${asin.imageUrl}`}
            alt="product"
            sx={{ borderRadius: '6px', minWidth: '70px', maxWidth: '70px', maxHeight: '70px' }}
          />
        )}
        {!asin.imageUrl && (
          <BlankImage
            style={{ borderRadius: '6px', minWidth: '70px', maxWidth: '70px', maxHeight: '70px' }}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {asin?.minPrice === asin?.maxPrice && (
          <Box sx={{ fontSize: '18px', fontWeight: '600', color: '#9B57D3' }}>
            {Intl.NumberFormat(region, {
              style: 'currency',
              currency: regionCurrency,
            }).format(asin?.minPrice?.toFixed(2))}
          </Box>
        )}
        {asin?.minPrice !== asin?.maxPrice && (
          <Box sx={{ fontSize: '18px', fontWeight: '600', color: '#9B57D3' }}>
            {Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
              asin?.minPrice?.toFixed(2),
            )}{' '}
            -
            {Intl.NumberFormat(region, { style: 'currency', currency: regionCurrency }).format(
              asin?.maxPrice?.toFixed(2),
            )}
          </Box>
        )}
        <Box
          sx={{
            fontSize: '14px',
            fontWeight: '600',
            color: 'greys.darkGrey',
          }}
        >
          {asin.name}
        </Box>
        {asin.variantTitle && (
          <Box
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: 'greys.grey',
            }}
          >
            {asin.variantTitle}
          </Box>
        )}
        <Box>{asin.asin}</Box>
      </Box>
    </Box>
  );
};

const OfferRatioRow = ({ asin, onClick }) => (
  <StyledTableRow>
    <StyledTableCell align="left" data-cy="dash_offer_link">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {asin.ratio >= 0.5 && (
          <ErrorRoundedIcon
            fontSize="small"
            sx={{ color: 'error.main', minHeight: '20px', minWidth: '20px' }}
          />
        )}
        {asin.ratio < 0.5 && <Box sx={{ minHeight: '20px', minWidth: '20px' }} />}
        <Tooltip title={<HoverAsin asin={asin} />} style={{ cursor: 'pointer' }}>
          <Box
            tabIndex="0"
            role="button"
            type="button"
            sx={{
              maxHeight: '22px',
              overflow: 'hidden',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => onClick(asin)}
          >
            {asin.name}
          </Box>
        </Tooltip>
      </Box>
    </StyledTableCell>
    <StyledTableCell align="right">
      <LinkButton
        sx={{
          textDecoration: 'underline',
          pl: '10px',
          pr: '10px',
          mr: '-10px',
          fontSize: '13px',
          color: (theme) => theme.colors.linkBlue,
        }}
        onClick={() => onClick(asin)}
      >
        {asin.offersCount}
      </LinkButton>
    </StyledTableCell>
    <StyledTableCell align="left">
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box
          sx={{
            width: '4ch',
            display: 'flex',
            justifyContent: 'flex-end',
            fontWeight: '600',
            color: asin.negative > 0 ? 'error.main' : 'greys.black',
          }}
        >
          {(asin.ratio * 100).toFixed(0)}%
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Box
            sx={{
              bgcolor: 'error.main',
              height: '4px',
              width: `${asin.ratio * 100}%`,
            }}
          />
        </Box>
      </Box>
    </StyledTableCell>
  </StyledTableRow>
);

const OffersRatio = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const { region } = useContext(AuthDataContext);
  const [asins, setAsins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [exportFilename, setExportFilename] = useState('');
  const [exportData, setExportData] = useState('');
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const [showAsin, setShowAsin] = useState(null);
  const [categories, setCategories] = useState(null);
  const [sellerProfile, setSellerProfile] = useState(null);

  const updateExport = () => {
    if (filteredAsins) {
      const csvData = [['ASIN', 'ProductLink', 'Name', 'Variant', 'Offers', 'Ratio']];
      for (let i = 0; i < filteredAsins.length; i += 1) {
        const item = filteredAsins[i];
        csvData.push([
          item.asin,
          formatStringForCSV(item.asinUrl),
          formatStringForCSV(item.name),
          formatStringForCSV(item.variantTitle),
          item.offersCount,
          item.ratio.toFixed(2),
        ]);
      }
      setExportData(csvData);
    }
  };

  const filterAsins = (a, c) => {
    let asinsToProcess = a;
    if (c) {
      const newAsins = [];
      for (let i = 0; i < a.length; i += 1) {
        const item = a[i];
        if (item.categoryId === c) {
          newAsins.push(item);
        }
      }
      asinsToProcess = newAsins;
    }
    let asinsToShow = asinsToProcess.map((asin) => ({
      asin: asin.asin,
      name: asin.name,
      offersCount: asin.offerCount,
      ratio: asin.score,
      minPrice: asin.minPrice,
      maxPrice: asin.maxPrice,
      variantTitle: asin.variantTitle,
      imageUrl: asin.imageUrl,
      asinUrl: asin.asinUrl,
    }));
    asinsToShow = asinsToShow.sort((l, r) => r.ratio - l.ratio);
    setFilteredAsins(asinsToShow);
  };

  const GET_OFFERS_RATIO_QUERY = gql`
    query GetScoreHistory($categoryId: Int) {
      getCategoryList(premiumTiers: true) {
        id
        name
      }
      getBadRatios(categoryId: $categoryId) {
        asin
        imageUrl
        asinUrl
        categoryId
        name
        variantTitle
        offerCount
        score
        minPrice
        maxPrice
      }
    }
  `;

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const sortedCategories = dataToProcess.getCategoryList
        ?.concat()
        .sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedCategories);
      setAsins(dataToProcess.getBadRatios);
      filterAsins(dataToProcess.getBadRatios);
      updateExport();
      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_OFFERS_RATIO_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      categoryId: category || null,
    },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  const updateExportFilename = () => {
    let filename = `${region}-OfferRatios`;
    if (category === null) {
      filename = `${filename}-all`;
    } else {
      for (let i = 0; i < categories.length; i += 1) {
        const cat = categories[i];
        if (cat.id === category) {
          filename = `${filename}-${cat.name}`;
          break;
        }
      }
    }
    filename = `${filename}-${getCurrentDateTime()}`;
    setExportFilename(filename);
  };

  useEffect(() => {
    updateExportFilename();
  }, [category, region]);

  useEffect(() => {
    updateExport();
  }, [filteredAsins]);

  const handleChooseCategory = (c) => {
    setLimit(scrollPageLength);
    filterAsins(asins, c);
    setCategory(c);
  };

  const loadMore = () => {
    if (limit < filteredAsins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > filteredAsins.length) {
        newLimit = filteredAsins.length;
      }
      setLimit(newLimit);
    }
  };

  const handleOffersClick = (asin) => {
    setShowAsin(asin);
  };

  return (
    <Widget>
      <WidgetHeader
        sx={{ pb: 0 }}
        title="Offer Ratios"
        right={
          <CSVLink target="_blank" filename={exportFilename} data={exportData}>
            <Download />
          </CSVLink>
        }
        bottom={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FilterDropdown
              label="Category"
              items={categories?.map((c) => ({
                value: c.id,
                title: c.name,
              }))}
              value={category}
              datacy="select_dropdown_option_Category"
              onSelect={handleChooseCategory}
              truncateLength={15}
            />
          </Box>
        }
      />
      <Box>
        <Box
          sx={{
            mt: 1,
            borderTop: (theme) => `1px solid ${theme.colors.lightGrey}`,
            maxHeight: '346px',
            overflowY: 'auto',
          }}
        >
          {loading && <Loading />}
          {!loading && (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={limit < filteredAsins.length}
              loader={
                limit < filteredAsins.length && (
                  <Box key={0}>
                    <Loading />
                  </Box>
                )
              }
              useWindow={false}
            >
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <StyledTableHeaderRow>
                      <StyledTableCell width="55%" align="left">
                        Product
                      </StyledTableCell>
                      <StyledTableCell width="10%" align="right">
                        Offers
                      </StyledTableCell>
                      <StyledTableCell width="35%" align="left">
                        Bad Ratio
                      </StyledTableCell>
                    </StyledTableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {filteredAsins.slice(0, limit).map((asin) => (
                      <OfferRatioRow key={asin.asin} asin={asin} onClick={handleOffersClick} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          )}
        </Box>
      </Box>
      <OffersDrawer
        asin={showAsin}
        open={!!showAsin}
        onClose={() => setShowAsin(null)}
        setSellerProfile={setSellerProfile}
      />
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfile !== null}
        onClose={() => setSellerProfile(null)}
      />
    </Widget>
  );
};

export default OffersRatio;
